import React, {useContext} from 'react';
import './client-stories.scss';
import { NavLink, Link } from "react-router-dom";
import { AppContext } from '../../../services/translationContext';

export const ClientStoriesSection = () => {
  const { services: {TranslationsService} } = useContext(AppContext);
  
  return (
    <div className='syd-bg-light'>
      <h2 className="syd-title light fw-bold text-uppercase pt-5 px-3">{TranslationsService.labels(`client_stories`)}</h2>
      {
        TranslationsService.labels(`hero_sections.client_stories.text`) &&
        <p className='p-3 fs-4'>{TranslationsService.labels(`hero_sections.client_stories.text`)}</p>
      }
      <div className='p-3 d-flex flex-column'>
        <div className='row gap-3 gap-lg-0'>

          {
            TranslationsService.labels(`client_stories_sect`).map((_cStory, i) => (
              _cStory.show_home &&
              <div className='col-sm-12 col-lg-3' key={i}>
                <Link to={`/insights/client-stories/${_cStory.id}`} className='text-deco-none tile-client-story'>
                  <div className='syd-box flat d-flex position-relative p-0 overflow-hidden syd-vertical-box'>
                    {/* <div className='bg-client-stories' style={{backgroundImage:`url(${_cStory.image_url})`}}></div> */}
                    <img src={_cStory.image_url} className='insights-image transition-03s-eio client-story-tile-img'></img>
                    {/* <div className='body-stories-sect d-flex flex-column h-100 w-100 p-4 bkg-tile-client-story'>
                      <h4 className="syd-title light fw-bold">{_cStory.title}</h4>
                      <p className='syd-paragraph fs-5 pt-2'>{_cStory.desc}</p>
                      <div className='row pt-5 mt-auto'>
                        <h5 className='dark-mode-text m-0 fs-6 d-flex flex-column flex-lg-row justify-content-between'>
                          <span className='fw-bold dark-mode-title'>{TranslationsService.labels(`services.${_cStory.bu_area.key}.title`)}
                          {
                            _cStory.bu_area.sub_items &&
                            <span> - {TranslationsService.labels('services')[_cStory.bu_area.key].items[_cStory.bu_area.sub_items].title}</span>
                          }
                          </span>
                          <span>
                            {
                              _cStory.bu_service.map((service, i) => (
                                _cStory.bu_area.sub_items ?
                                (
                                  <p key={i} className='m-0 text-end'>{TranslationsService.labels('services')[_cStory.bu_area.key].items[_cStory.bu_area.sub_items][service].title}</p>
                                )
                                :
                                (
                                  <p key={i} className='m-0'>{TranslationsService.labels(`services.${_cStory.bu_area.key}.${_cStory.bu_service}.title`)}</p>
                                )
                              ))
                            }
                          </span>
                        </h5>
                      </div>
                    </div> */}
                    <div className='body-stories-sect d-flex flex-column h-100 w-100 p-4 bkg-tile-client-story syd-vertical-box-body'>
                      <h4 className="syd-title light fw-bold">{_cStory.title}</h4>
                      {
                        _cStory.bu_area.sub_items &&
                        <div className='d-flex align-items-center gap-2'>
                          <div style={{height:'0.6rem', width:'0.6rem', borderRadius:50}} className='bg-main-color'></div>
                          <p className='m-0 text-uppercase' style={{color:'#fff', fontSize:'0.8rem'}}>
                            {TranslationsService.labels(`services.${_cStory.bu_area.key}.title`)}
                              {
                                <span> - {TranslationsService.labels('services')[_cStory.bu_area.key].items[_cStory.bu_area.sub_items].title}</span>
                              }
                          </p>
                        </div>
                      }
                      <br/>
                      {
                        _cStory.bu_service.map((service, i) => (
                          _cStory.bu_area.sub_items ?
                          (
                            // <p key={i} className='m-0 text-end'>{TranslationsService.labels('services')[_cStory.bu_area.key].items[_cStory.bu_area.sub_items][service].title}</p>
                            <div className='d-flex align-items-center gap-2' key={i}>

                              <p className='m-0 text-uppercase' style={{color:'#fff', fontSize:'0.8rem'}}>
                              {TranslationsService.labels('services')[_cStory.bu_area.key].items[_cStory.bu_area.sub_items][service].title}
                              </p>
                            </div>
                          )
                          :
                          (
                            <p key={i} className='m-0'>{TranslationsService.labels(`services.${_cStory.bu_area.key}.${_cStory.bu_service}.title`)}</p>
                          )
                        ))
                      }


                      </div>
                  </div>
                </Link>
              </div>
            ))
          }

        </div>

        <NavLink to="/insights/client-stories" className="fw-bold fs-4 ref-syd-nav transition-03s-eio light-mode-text px-2 py-3">
          {TranslationsService.labels('view_all_stories')}
          <svg viewBox="0 0 7.48 11.59" className='icon-arrow ms-2'><polyline className="arrow-all transition-03s-eio" stroke='currentColor' points="1 1 6.48 5.8 1 10.59"/></svg>
        </NavLink>
      </div>
    </div>
  );
};