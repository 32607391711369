import React, { useContext } from "react";
import './sitemap.scss';
import { Link } from "react-router-dom";
import { AppContext } from '../../services/translationContext';

export const Sitemap = () => {
  const { services: { TranslationsService } } = useContext(AppContext);
  document.title = `Sitemap | ${TranslationsService.getMainInfoCompany('name')}`;

  return (
    <div className="section-home light p-5">
      <h4 className='dark-mode-title fs-5 m-0'>About Sydea</h4>
      <h2 className='syd-black fw-bold fs-1'>Sitemap</h2>

      <div id="syd-sitemap" className="section-sitemap py-5">
        <div className="row">
        {/* {
          TranslationsService.sectionAvailable('services') &&
          <div className="col-sm-12 col-lg-4">
            <Link to="/services" className="text-deco-none fw-bold fs-3">{TranslationsService.labels(`menu.services.label`)}</Link>
            {
              Object.keys(TranslationsService.labels('services')).map((area,indice) => (
                <ul key={indice}>
                  <li>
                    <Link to={`/services/${area}`} className='text-deco-none fw-bold fs-5'>{TranslationsService.labels('services')[area].title}</Link>
                  </li>
                  <ul>
                    {
                      Object.keys(TranslationsService.labels('services')[area]).map((subbu,i) => (
                        TranslationsService.labels('services')[area][subbu].title &&
                        <li key={i}>
                          <Link to={`/services/${area}/${subbu}`} className='text-deco-none fs-6'>{TranslationsService.labels('services')[area][subbu].title}</Link>
                        </li>
                      ))
                    }
                  </ul>
                </ul>
              ))
            }
          </div>
        } */}

        {
          TranslationsService.sectionAvailable('services') &&
          <div className="col-sm-12 col-lg-4">
            <Link to="/services" className="text-deco-none fw-bold fs-3">{TranslationsService.labels(`menu.services.label`)}</Link>
            {
              Object.keys(TranslationsService.labels('services')).map((area,indice) => (
                <ul key={indice}>
                  <li>
                    <Link to={`/services/${area}`} className='text-deco-none fw-bold fs-5'>{TranslationsService.labels('services')[area].title}</Link>
                  </li>
                  <ul>
                    {
                        TranslationsService.labels('services')[area].items ?
                        (
                        Object.keys(TranslationsService.labels('services')[area].items).map((_sub, i) => (
                            <ul key={i}>
                                <b>{TranslationsService.labels('services')[area].items[_sub].title}</b>
                            {
                                TranslationsService.labels('services')[area].items[_sub] ?
                                (
                                Object.keys(TranslationsService.labels('services')[area].items[_sub]).map((_subItem, j) => (
                                    typeof TranslationsService.labels('services')[area].items[_sub][_subItem] === 'object' &&
                                    TranslationsService.labels('services')[area].items[_sub][_subItem].title ?
                                    (
                                    <li key={j} className="ms-2">
                                        <Link to={`/services/${area}/${_sub}/${_subItem}`} className='text-deco-none fs-6'>
                                        {TranslationsService.labels('services')[area].items[_sub][_subItem].title}
                                        </Link>
                                    </li>
                                    ) : null
                                ))
                                ) : null
                            }
                            </ul>
                        ))
                        )
                        :
                        (
                        Object.keys(TranslationsService.labels('services')[area]).map((_sub, i) => (
                            TranslationsService.labels('services')[area][_sub].title ?
                            (
                            <li key={i}>
                                <Link to={`/services/${area}/${_sub}`} className='text-deco-none fs-6'>
                                {TranslationsService.labels('services')[area][_sub].title}
                                </Link>
                            </li>
                            ) : null
                        ))
                        )
                    }
                  </ul>
                </ul>
              ))
            }
          </div>
        }

        {
          TranslationsService.sectionAvailable('products') &&
          <div className="col-sm-12 col-lg-4">
          <Link to="/products" className="text-deco-none fw-bold fs-3">{TranslationsService.labels(`menu.products.label`)}</Link>
            <ul>
              {
                Object.keys(TranslationsService.labels('products')).map((_sub,indice) => (
                  <li key={indice}>
                    <Link to={`/products/${_sub}`} className='text-deco-none fs-6'>{TranslationsService.labels(`products.${_sub}.title`)}</Link>
                  </li>
                ))
              }
            </ul>
            <Link to="/industries" className="text-deco-none fw-bold fs-3">{TranslationsService.labels(`menu.industries.label`)}</Link>
            <ul>
              {
                Object.keys(TranslationsService.labels('industries')).map((_sub,indice) => (
                  <li key={indice}>
                    <Link to={`/industries/${_sub}`} className='text-deco-none fs-6'>{TranslationsService.labels(`industries.${_sub}.title`)}</Link>
                  </li>
                ))
              }
            </ul>
          </div>
          }
          
          <div className="col-sm-12 col-lg-4">
          {
          TranslationsService.sectionAvailable('insights') &&
          <>
            <Link to="/insights" className="text-deco-none fw-bold fs-3">{TranslationsService.labels(`menu.insights.label`)}</Link>
            <ul>
            {
              TranslationsService.childMenuAvailable('insightsSections.blog') &&
              <li>
                <Link to="/insights/blog" className='text-deco-none fs-6'>Blog</Link>
              </li>
            }
            {
              TranslationsService.childMenuAvailable('insightsSections.client-stories') &&
              <li>
                <Link to="/insights/client-stories" className='text-deco-none fs-6'>{TranslationsService.labels('client_stories')}</Link>
              </li>
            }
            </ul>
            </>
          }

          {
            TranslationsService.sectionAvailable('about') &&
            <>
            <Link to="/about" className="text-deco-none fw-bold fs-3">{TranslationsService.labels(`menu.about.label`)}</Link>
            <ul>
              <li>
                  <Link to="/about" className="text-deco-none fs-6">{TranslationsService.labels('our_history')}</Link>
              </li>
              {
                TranslationsService.childMenuAvailable('aboutSections.r&d') && 
                <li>
                  <Link to="/about/rnd" className="text-deco-none fs-6">R&D</Link>
                </li>
              }
              {
                TranslationsService.childMenuAvailable('aboutSections.partners') && 
                <li>
                  <Link to="/about/our-partners" className="text-deco-none fs-6">{TranslationsService.labels('our_partners')}</Link>
                </li>
              }
              {
                TranslationsService.childMenuAvailable('aboutSections.certifications') && 
                <li>
                  <Link to="/about/our-certifications" className="text-deco-none fs-6">{TranslationsService.labels('our_certifications')}</Link>
                </li>
              }
              <li>
                  <Link to="/about/the-real-submarine" className="text-deco-none fs-6">The Real Submarine</Link>
              </li>
            </ul>
            </>
            }

            {
              TranslationsService.sectionAvailable('careers') &&
                <Link to="/careers" className="text-deco-none fw-bold fs-3">{TranslationsService.labels(`menu.careers.label`)}</Link>
            }
            
            <br/>

            {/* <ul>
                <Link to={`careers/roles`} className="text-deco-none fs-6">Explore our vacancies</Link>
            </ul>
            <ul>
              <Link to={`careers/roles`} className="text-deco-none fw-bold fs-5">{TranslationsService.labels(`explore_all_roles`)}</Link>
              <ul>
                {
                  Object.keys(TranslationsService.labels(`roles`)).map((_sub, i) => (
                    <li key={i}>
                        <Link to={`careers/roles/${_sub}`} className="text-deco-none fs-6">{TranslationsService.labels(`roles.${_sub}.name`)}</Link>
                    </li>
                  ))
                }
              </ul>
            </ul> */}
            
            {
              TranslationsService.sectionAvailable('contacts') &&
              <Link to="/contacts" className="text-deco-none fw-bold fs-3">{TranslationsService.labels(`menu.contact-us.label`)}</Link>
            }

            
            

            {/* <ul className="d-grid">
              <Link to='/contacts' className="text-deco-none fw-bold fs-5">{TranslationsService.labels(`menu.contact-us.label`)}</Link>
              <Link to='/privacy-policy' className="text-deco-none fw-bold fs-5">Privacy Policy</Link>
              <Link to='/contacts' className="text-deco-none fw-bold fs-5">Cookie Policy</Link>
            </ul> */}

          </div>
        </div>
      </div>
    </div>
  );
};