import './vector-animated.scss';

export const VectorAnimated = ({animationName}) => {

  switch (animationName){
    // case 'application-managed-service':
    //   return <div className='container-anim-bg d-flex justify-content-end'><svg viewBox="0 0 1364.72 434.52"><polyline className="cls-1-services svg-elem-1-managed-service-2" points="1364.72 376.6 1295.89 376.6 1295.89 91.52 1079.89 91.52 1079.89 433.52 456.89 433.52 456.89 380.02 0 380.02"></polyline><rect className="cls-1-services svg-elem-2-managed-service-2" x="866.15" y="1" width="292" height="292"></rect></svg></div>;
    // case 'edi-integrations':
    //   return <div className='container-anim-bg d-flex justify-content-end'><svg viewBox="0 0 1369.13 454.99"><defs><style></style></defs><path className="cls-1-services svg-elem-1-edi-integrations" d="m.04,428.16c75.77,3.3,187.71,5.83,323.4,0,243.55-10.47,367.74-16.91,485.96-76.09,111.29-55.71,209.97-144.64,266.33-195.42C1150.55,89.22,1201.62,1.1,1286.71,1c35-.04,63.6,13.93,81.89,25.33"></path><circle className="cls-1-services svg-elem-2-edi-integrations" cx="1168.81" cy="188.23" r="168.18"></circle></svg></div>;
    // case 'cloud-solutions':
    //   return <div className='container-anim-bg d-flex justify-content-end'><svg viewBox="0 0 1387 291.23"><path className="cls-1-services cloud-solutions-1" d="m15.07,288.88h1239.41c37.16-9.21,64.12-40.68,66.73-77.11,2.91-40.5-25.38-71.45-52.64-84.53-23.74-11.39-46.4-9.08-56.36-7.41.34-4.35,1.29-22.83-11.86-39.3-16.02-20.08-39.84-20.73-43.01-20.77-14.28-.14-24.92,5.33-29.66,8.16-5.74-10.55-17.83-29.37-40.04-44.49-10.14-6.9-45.63-28.85-91.94-20.02-9.77,1.86-47.93,9.78-73.41,45.97-17.63,25.04-20.35,51.07-20.35,65.52,0,3.71.18,6.66.33,8.63-8.88,1.29-21.82,4.61-33.52,14.02-28.86,23.18-31.53,68.36-19.71,99.85,9.98,26.59,29.94,42.48,46.24,51.48h485.84"></path></svg></div>;
    // case 'open-text':
    //   return <div className='container-anim-bg d-flex justify-content-end'><svg viewBox="0 0 1368.72 466.67"><g id="Livello_2"><path className="cls-1-services svg-elem-1-open-text" d="m1145.63,398.43H1.89"></path></g><g id="Livello_3"><circle className="cls-1-services svg-elem-2-open-text" cx="1151.72" cy="286.41" r="112.2"></circle><circle className="cls-1-services svg-elem-3-open-text" cx="1179.95" cy="286.41" r="140.43"></circle><path className="cls-1-services svg-elem-4-open-text" d="m1367.89,186.85c-32.15-48.06-86.93-79.71-149.1-79.71-98.39,0-178.29,79.28-179.26,177.44,0,.61,0,1.21,0,1.82s0,1.21,0,1.82c.41,41.68,15.06,79.96,39.31,110.21,32.85,40.99,83.34,67.24,139.95,67.24,62.17,0,116.95-31.64,149.1-79.71"></path></g></svg></div>;
    // case 'open-edition':
    //   return <div className='container-anim-bg d-flex justify-content-end'><svg viewBox="0 0 1367.89 436.75"><polyline className="cls-1-services open-edition-1" points="1.89 406.05 507.83 406.05 524.54 422.76 782.6 422.76 795.59 435.75 957.11 435.75 978.46 414.4 1022.09 414.4 1070.82 365.67 1105.63 365.67 1204.26 267.04 1228.17 267.04 1341.53 153.68 1367.89 153.68"></polyline></svg></div>;
    // case 'neptune-dxp-platform':
    //  return <div className='container-anim-bg d-flex justify-content-end'><svg viewBox="0 0 1366 380.01"><polyline className="cls-1-services neptune-dxp-platform-1" points="0 333.55 817.84 333.55 900.86 333.55 900.86 240.16 900.86 170.98 1004.62 170.98 1092.82 170.98 1092.82 240.16 1004.62 240.16 900.86 240.16 817.84 240.16 817.84 333.55 817.84 378.51 1004.62 378.51 1004.62 240.16 1004.62 170.98 1004.62 1.5 1170.64 1.5 1170.64 198.65 1366 198.65"></polyline></svg></div>;
    // case 'ar-vr':
    //   return <div className='container-anim-bg d-flex justify-content-end'><svg viewBox="0 0 1368 403.6"><polyline className="cls-1-services ar-vr-1" points="1 398.87 953.77 398.87 953.77 43.96 1006.07 43.96 1162.98 43.96 1162.98 402.6 1006.07 331.62 1006.07 43.96 1006.07 1 1273.19 1 1273.19 269.98 1367 269.98"></polyline></svg></div>;
    // case 'web-mobile-development':
    //   return <div className='container-anim-bg d-flex justify-content-end'><svg viewBox="0 0 1368 256.49"><polyline className="cls-1-services web-mobile-development-1" points="1 255.28 921.96 255.28 1052.44 255.28 1077.54 255.28 1077.54 106.39 1077.54 1 923.63 1 921.96 255.28 1052.44 255.28 1077.54 255.28 1267.48 255.28 1271.59 255.28 1271.59 106.39 1077.54 106.39 1052.44 106.39 1052.44 255.28 1052.44 255.49 1267.48 255.28 1271.59 255.28 1367 255.18"></polyline></svg></div>;
    // case 'technical-solutions':
    //   return <div className='container-anim-bg d-flex justify-content-end'><svg viewBox="0 0 1368 389.32"><polyline className="cls-1-services technical-solutions-1" points="1 388.32 918.28 388.32 918.28 233.29 1035.96 115.61 1220.88 300.53 1367 1"></polyline></svg></div>;
    // case 'implementation-roll-out-projects':
    //   return <div className='container-anim-bg d-flex justify-content-end'><svg viewBox="0 0 1367.89 434.52"><line className="cls-1-services implementation-roll-out-projects-1" x1="1367.89" y1="426.2" x2="1.89" y2="426.2"></line><path className="cls-1-services implementation-roll-out-projects-2" d="m1367.89,240.77h-175.45c-16.9,0-32.2-6.85-43.27-17.92-11.07-11.08-17.92-26.37-17.92-43.27,0-33.8,27.39-61.19,61.19-61.19h44.5c33.8,0,61.19,27.39,61.19,61.19v98.28c0,33.79-27.39,61.19-61.19,61.19h-183.58c-33.79,0-61.19-27.4-61.19-61.19V85.01c0-33.8,27.4-61.19,61.19-61.19h314.53"></path></svg></div>;
    // case 'integration':
    //   return <div className='container-anim-bg d-flex justify-content-end'><svg viewBox="0 0 1368.41 434.52"><polyline className="cls-1-services integration-1" points="853.6 394.19 768.01 426.2 1.89 426.2"></polyline><rect className="cls-1-services integration-2" x="1010.19" y="55.32" width="327.02" height="327.02"></rect><rect className="cls-1-services integration-3" x="853.6" y="215.25" width="178.94" height="178.94"></rect><line className="cls-1-services integration-4" x1="1367.89" y1="401.06" x2="1337.21" y2="382.33"></line></svg></div>;
    // case 'consulting-services':
    //   return <div className='container-anim-bg d-flex justify-content-end'><svg viewBox="0 0 1367.89 452.83"><path className="cls-1-services consulting-services-1" d="m1.89,426.2h871.59c20.18,0,36.54-16.36,36.54-36.54v-179c0-20.18,16.36-36.54,36.54-36.54h75.17c20.18,0,36.54,16.36,36.54,36.54v121.11c0,10.09,4.09,19.23,10.7,25.84,6.62,6.61,15.75,10.7,25.84,10.7,20.18,0,36.54-16.36,36.54-36.54v-190.01c0-20.18,16.36-36.54,36.54-36.54h45.94c20.18,0,36.54,16.36,36.54,36.54v87.7c0,20.18-16.36,36.54-36.54,36.54h-181.66c-20.18,0-36.54,16.36-36.54,36.54v112.75c0,20.18,16.36,36.54,36.54,36.54h335.72"></path></svg></div>;
    // case 'mobile-development':
    //   return <div className='container-anim-bg d-flex justify-content-end'><svg viewBox="0 0 1367.89 434.52"><polyline className="cls-1-services mobile-development-1" points="1.89 426.2 768.01 426.2 1231.58 426.2 1231.58 293.14 1231.58 96.87 910.02 96.87 910.02 359.96 1175.2 359.96 1175.2 293.14 983.1 293.14 983.1 163.68 1175.2 163.68 1175.2 293.14 1231.58 293.14 1367.89 293.14"></polyline></svg></div>;
    // case 'sap-edition':
    //   return <div className='container-anim-bg d-flex justify-content-end'><svg viewBox="0 0 1367.89 446.49"><path className="cls-1-services sap-edition-1" d="m1.89,426.2h812.32c9,0,17.16-3.65,23.05-9.55,5.9-5.9,9.55-14.05,9.55-23.06,0-18.01,14.6-32.6,32.61-32.6h115.86c18.01,0,32.61-14.6,32.61-32.61v-55.5c0-18.01,14.6-32.61,32.61-32.61h113.44c18.01,0,32.61-14.6,32.61-32.61v-60.33c0-18.01,14.6-32.61,32.61-32.61h28.94c18.01,0,32.61,14.6,32.61,32.61v94.13c0,18.01-14.6,32.61-32.61,32.61h-149.66c-18.01,0-32.61,14.6-32.61,32.61v106.2c0,18.01,14.6,32.61,32.61,32.61h249.45"></path></svg></div>;
    // case 'sap-technical-functionals-courses':
    //   return <div className='container-anim-bg d-flex justify-content-end'><svg viewBox="0 0 1368.85 434.52"><polyline className="cls-1-services sap-technical-functionals-courses-1" points="1.89 426.2 768.01 426.2 846.81 426.2 1035.13 126.8 1109.98 126.8 1213.79 319.94 1283.81 319.94 1367.89 37.12"></polyline></svg></div>;
    // case 'devops':
    //   return <div className='container-anim-bg d-flex justify-content-end'><svg viewBox="0 0 1366.09 527.56"><polyline className="cls-1-services devops-1" points="0 434.13 1156.26 434.13 1156.26 247.76 1094.55 247.76 1270.95 109.01 1365.17 183.12"></polyline></svg></div>;
    // case 'odoo':
    // default:
    //   return <div className='container-anim-bg d-flex justify-content-end'><svg viewBox="0 0 1368.85 434.52"><polyline className="cls-1-services sap-technical-functionals-courses-1" points="1.89 426.2 768.01 426.2 846.81 426.2 1035.13 126.8 1109.98 126.8 1213.79 319.94 1283.81 319.94 1367.89 37.12"></polyline></svg></div>;
    case 'square':
      return <div className='container-anim-bg d-flex justify-content-end'><svg viewBox="0 0 1364.72 434.52"><polyline className="cls-1-services svg-elem-1-managed-service-2" points="1364.72 376.6 1295.89 376.6 1295.89 91.52 1079.89 91.52 1079.89 433.52 456.89 433.52 456.89 380.02 0 380.02"></polyline><rect className="cls-1-services svg-elem-2-managed-service-2" x="866.15" y="1" width="292" height="292"></rect></svg></div>;
    case 'yoyo':
      return <div className='container-anim-bg d-flex justify-content-end'><svg viewBox="0 0 1369.13 454.99"><defs><style></style></defs><path className="cls-1-services svg-elem-1-edi-integrations" d="m.04,428.16c75.77,3.3,187.71,5.83,323.4,0,243.55-10.47,367.74-16.91,485.96-76.09,111.29-55.71,209.97-144.64,266.33-195.42C1150.55,89.22,1201.62,1.1,1286.71,1c35-.04,63.6,13.93,81.89,25.33"></path><circle className="cls-1-services svg-elem-2-edi-integrations" cx="1168.81" cy="188.23" r="168.18"></circle></svg></div>;
    case 'cloud':
      return <div className='container-anim-bg d-flex justify-content-end'><svg viewBox="0 0 1387 291.23"><path className="cls-1-services cloud-solutions-1" d="m15.07,288.88h1239.41c37.16-9.21,64.12-40.68,66.73-77.11,2.91-40.5-25.38-71.45-52.64-84.53-23.74-11.39-46.4-9.08-56.36-7.41.34-4.35,1.29-22.83-11.86-39.3-16.02-20.08-39.84-20.73-43.01-20.77-14.28-.14-24.92,5.33-29.66,8.16-5.74-10.55-17.83-29.37-40.04-44.49-10.14-6.9-45.63-28.85-91.94-20.02-9.77,1.86-47.93,9.78-73.41,45.97-17.63,25.04-20.35,51.07-20.35,65.52,0,3.71.18,6.66.33,8.63-8.88,1.29-21.82,4.61-33.52,14.02-28.86,23.18-31.53,68.36-19.71,99.85,9.98,26.59,29.94,42.48,46.24,51.48h485.84"></path></svg></div>;
    case 'circles':
      return <div className='container-anim-bg d-flex justify-content-end'><svg viewBox="0 0 1368.72 466.67"><g id="Livello_2"><path className="cls-1-services svg-elem-1-open-text" d="m1145.63,398.43H1.89"></path></g><g id="Livello_3"><circle className="cls-1-services svg-elem-2-open-text" cx="1151.72" cy="286.41" r="112.2"></circle><circle className="cls-1-services svg-elem-3-open-text" cx="1179.95" cy="286.41" r="140.43"></circle><path className="cls-1-services svg-elem-4-open-text" d="m1367.89,186.85c-32.15-48.06-86.93-79.71-149.1-79.71-98.39,0-178.29,79.28-179.26,177.44,0,.61,0,1.21,0,1.82s0,1.21,0,1.82c.41,41.68,15.06,79.96,39.31,110.21,32.85,40.99,83.34,67.24,139.95,67.24,62.17,0,116.95-31.64,149.1-79.71"></path></g></svg></div>;
    case 'mountain':
      return <div className='container-anim-bg d-flex justify-content-end'><svg viewBox="0 0 1367.89 436.75"><polyline className="cls-1-services open-edition-1" points="1.89 406.05 507.83 406.05 524.54 422.76 782.6 422.76 795.59 435.75 957.11 435.75 978.46 414.4 1022.09 414.4 1070.82 365.67 1105.63 365.67 1204.26 267.04 1228.17 267.04 1341.53 153.68 1367.89 153.68"></polyline></svg></div>;
    case '  ':
     return <div className='container-anim-bg d-flex justify-content-end'><svg viewBox="0 0 1366 380.01"><polyline className="cls-1-services neptune-dxp-platform-1" points="0 333.55 817.84 333.55 900.86 333.55 900.86 240.16 900.86 170.98 1004.62 170.98 1092.82 170.98 1092.82 240.16 1004.62 240.16 900.86 240.16 817.84 240.16 817.84 333.55 817.84 378.51 1004.62 378.51 1004.62 240.16 1004.62 170.98 1004.62 1.5 1170.64 1.5 1170.64 198.65 1366 198.65"></polyline></svg></div>;
    case 'doors':
      return <div className='container-anim-bg d-flex justify-content-end'><svg viewBox="0 0 1368 403.6"><polyline className="cls-1-services ar-vr-1" points="1 398.87 953.77 398.87 953.77 43.96 1006.07 43.96 1162.98 43.96 1162.98 402.6 1006.07 331.62 1006.07 43.96 1006.07 1 1273.19 1 1273.19 269.98 1367 269.98"></polyline></svg></div>;
    case 'webMobile':
      return <div className='container-anim-bg d-flex justify-content-end'><svg viewBox="0 0 1368 256.49"><polyline className="cls-1-services web-mobile-development-1" points="1 255.28 921.96 255.28 1052.44 255.28 1077.54 255.28 1077.54 106.39 1077.54 1 923.63 1 921.96 255.28 1052.44 255.28 1077.54 255.28 1267.48 255.28 1271.59 255.28 1271.59 106.39 1077.54 106.39 1052.44 106.39 1052.44 255.28 1052.44 255.49 1267.48 255.28 1271.59 255.28 1367 255.18"></polyline></svg></div>;
    case 'lineUp':
      return <div className='container-anim-bg d-flex justify-content-end'><svg viewBox="0 0 1368 389.32"><polyline className="cls-1-services technical-solutions-1" points="1 388.32 918.28 388.32 918.28 233.29 1035.96 115.61 1220.88 300.53 1367 1"></polyline></svg></div>;
    case 'tail':
      return <div className='container-anim-bg d-flex justify-content-end'><svg viewBox="0 0 1367.89 434.52"><line className="cls-1-services implementation-roll-out-projects-1" x1="1367.89" y1="426.2" x2="1.89" y2="426.2"></line><path className="cls-1-services implementation-roll-out-projects-2" d="m1367.89,240.77h-175.45c-16.9,0-32.2-6.85-43.27-17.92-11.07-11.08-17.92-26.37-17.92-43.27,0-33.8,27.39-61.19,61.19-61.19h44.5c33.8,0,61.19,27.39,61.19,61.19v98.28c0,33.79-27.39,61.19-61.19,61.19h-183.58c-33.79,0-61.19-27.4-61.19-61.19V85.01c0-33.8,27.4-61.19,61.19-61.19h314.53"></path></svg></div>;
    case 'perspective':
      return <div className='container-anim-bg d-flex justify-content-end'><svg viewBox="0 0 1368.41 434.52"><polyline className="cls-1-services integration-1" points="853.6 394.19 768.01 426.2 1.89 426.2"></polyline><rect className="cls-1-services integration-2" x="1010.19" y="55.32" width="327.02" height="327.02"></rect><rect className="cls-1-services integration-3" x="853.6" y="215.25" width="178.94" height="178.94"></rect><line className="cls-1-services integration-4" x1="1367.89" y1="401.06" x2="1337.21" y2="382.33"></line></svg></div>;
    case 'track':
      return <div className='container-anim-bg d-flex justify-content-end'><svg viewBox="0 0 1367.89 452.83"><path className="cls-1-services consulting-services-1" d="m1.89,426.2h871.59c20.18,0,36.54-16.36,36.54-36.54v-179c0-20.18,16.36-36.54,36.54-36.54h75.17c20.18,0,36.54,16.36,36.54,36.54v121.11c0,10.09,4.09,19.23,10.7,25.84,6.62,6.61,15.75,10.7,25.84,10.7,20.18,0,36.54-16.36,36.54-36.54v-190.01c0-20.18,16.36-36.54,36.54-36.54h45.94c20.18,0,36.54,16.36,36.54,36.54v87.7c0,20.18-16.36,36.54-36.54,36.54h-181.66c-20.18,0-36.54,16.36-36.54,36.54v112.75c0,20.18,16.36,36.54,36.54,36.54h335.72"></path></svg></div>;
    case 'labyrinth':
      return <div className='container-anim-bg d-flex justify-content-end'><svg viewBox="0 0 1367.89 434.52"><polyline className="cls-1-services mobile-development-1" points="1.89 426.2 768.01 426.2 1231.58 426.2 1231.58 293.14 1231.58 96.87 910.02 96.87 910.02 359.96 1175.2 359.96 1175.2 293.14 983.1 293.14 983.1 163.68 1175.2 163.68 1175.2 293.14 1231.58 293.14 1367.89 293.14"></polyline></svg></div>;
    case 'sea':
      return <div className='container-anim-bg d-flex justify-content-end'><svg viewBox="0 0 1367.89 446.49"><path className="cls-1-services sap-edition-1" d="m1.89,426.2h812.32c9,0,17.16-3.65,23.05-9.55,5.9-5.9,9.55-14.05,9.55-23.06,0-18.01,14.6-32.6,32.61-32.6h115.86c18.01,0,32.61-14.6,32.61-32.61v-55.5c0-18.01,14.6-32.61,32.61-32.61h113.44c18.01,0,32.61-14.6,32.61-32.61v-60.33c0-18.01,14.6-32.61,32.61-32.61h28.94c18.01,0,32.61,14.6,32.61,32.61v94.13c0,18.01-14.6,32.61-32.61,32.61h-149.66c-18.01,0-32.61,14.6-32.61,32.61v106.2c0,18.01,14.6,32.61,32.61,32.61h249.45"></path></svg></div>;
    case 'positive':
      return <div className='container-anim-bg d-flex justify-content-end'><svg viewBox="0 0 1368.85 434.52"><polyline className="cls-1-services sap-technical-functionals-courses-1" points="1.89 426.2 768.01 426.2 846.81 426.2 1035.13 126.8 1109.98 126.8 1213.79 319.94 1283.81 319.94 1367.89 37.12"></polyline></svg></div>;
    case 'arrow':
      return <div className='container-anim-bg d-flex justify-content-end'><svg viewBox="0 0 1366.09 527.56"><polyline className="cls-1-services devops-1" points="0 434.13 1156.26 434.13 1156.26 247.76 1094.55 247.76 1270.95 109.01 1365.17 183.12"></polyline></svg></div>;
    default:
      return <div className='container-anim-bg d-flex justify-content-end'><svg viewBox="0 0 1368.85 434.52"><polyline className="cls-1-services sap-technical-functionals-courses-1" points="1.89 426.2 768.01 426.2 846.81 426.2 1035.13 126.8 1109.98 126.8 1213.79 319.94 1283.81 319.94 1367.89 37.12"></polyline></svg></div>;
  };
};