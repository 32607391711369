import React, {useContext} from 'react';
import { AppContext } from '../../../services/translationContext';
import './insights.scss';
import { Link, NavLink } from "react-router-dom";

export const InsightsSection = () => {
  const { services: {TranslationsService} } = useContext(AppContext);

  return (
    <div className="bkg-light d-flex flex-column">
      <h2 className='syd-title dark fw-bold text-uppercase pt-5 pb-3 px-3'>{TranslationsService.labels('latest_insights')}</h2>
      {/* <div className='syd-grid'>
      {
          TranslationsService.labels(`home_page.carousel`).slice(0, 3).map((slider, i) => (
          <div className={`grid-card-s-${i}`} key={i}>
            {
              slider.internal_link ?
              (
                <Link to={`${slider.internal_link}`} className='insights-card text-deco-none transition-03s-eio'>
                  <img className='insights-image transition-03s-eio' src={slider.image} alt={slider.title}></img>
                  <div className='insights-card-content'>
                    <div className='insights-card-body'>
                      <h4 className='insights-title-card m-0 fw-bold'>{slider.title}</h4>
                    </div>
                  </div>
                </Link>
              )
              :
              (
                <a href={slider.external_link} target='_blank' className='insights-card text-deco-none transition-03s-eio'>
                  <img className='insights-image transition-03s-eio' src={slider.image} alt={slider.title}></img>
                  <div className='insights-card-content'>
                    <div className='insights-card-body'>
                      <h4 className='insights-title-card m-0 fw-bold'>{slider.title}</h4>
                    </div>
                  </div>
                </a>
              )
            }
          </div>
          ))
        }
      </div> */}
      <div className='p-3 d-flex flex-column'>
        <div className='row gap-3 gap-lg-0'>
        {
            TranslationsService.labels(`home_page.carousel`).slice(0, 4).map((slider, i) => (
            <div className='col-sm-12 col-lg-3' key={i}>
              {
                // slider.internal_link ?
                // (
                  <Link to={`${slider.internal_link ? slider.internal_link : slider.external_link}`} className='insights-card-prev text-deco-none tile-client-story' target={slider.internal_link ? '_self':'_blank'}>
                    <div className='syd-box flat d-flex position-relative p-0 overflow-hidden syd-vertical-box'>
                      <img src={slider.image} alt={slider.title} className='insights-image transition-03s-eio client-story-tile-img'></img>
                      <div className='body-stories-sect d-flex flex-column h-100 w-100 p-4 bkg-tile-client-story syd-vertical-box-body'>
                        <h4 className="insights-title-card fw-bold">{slider.title}</h4>
                      </div>
                    </div>
                  </Link>
                  // <Link to={`${slider.internal_link}`} className='insights-card text-deco-none transition-03s-eio'>
                  //   <img className='insights-image transition-03s-eio' src={slider.image} alt={slider.title}></img>
                  //   <div className='insights-card-content'>
                  //     <div className='insights-card-body'>
                  //       <h4 className='insights-title-card m-0 fw-bold'>{slider.title}</h4>
                  //     </div>
                  //   </div>
                  // </Link>
                // )
                // :
                // (
                //   <a href={slider.external_link} target='_blank' className='insights-card text-deco-none transition-03s-eio'>
                //     <img className='insights-image transition-03s-eio' src={slider.image} alt={slider.title}></img>
                //     <div className='insights-card-content'>
                //       <div className='insights-card-body'>
                //         <h4 className='insights-title-card m-0 fw-bold'>{slider.title}</h4>
                //       </div>
                //     </div>
                //   </a>
                // )
              }
            </div>
            ))
          }
        </div>
      </div>
      {
        TranslationsService.childMenuAvailable('insightsSections.blog') &&
        <NavLink to="/insights/blog" className="fw-bold fs-4 ref-syd-nav transition-03s-eio light-mode-text px-2 py-3">
          {TranslationsService.labels('see_all_news')}
          <svg viewBox="0 0 7.48 11.59" className='icon-arrow ms-2'><polyline className="arrow-all transition-03s-eio" stroke='currentColor' points="1 1 6.48 5.8 1 10.59"/></svg>
        </NavLink>
      }
    </div>
  );
};