import React, {useContext} from 'react';
import "./certifications.scss";
import { AppContext } from '../../services/translationContext';
import { Link } from 'react-router-dom';

export const Certifications = () => {
  const { services: {TranslationsService} } = useContext(AppContext);
  document.title = `${TranslationsService.labels('our_certifications')} | ${TranslationsService.getMainInfoCompany('name')}`;

  const text_test = `
  <p>The <b>AWS Developer Associate certification</b> is aimed at professionals who have practical skills in developing and maintaining applications on AWS. To earn this certification, candidates must pass an exam that covers various aspects of application development on AWS, including coding, using compute services, implementing and managing resources, application testing, and security.<br/>Holders of the AWS Developer Associate certification demonstrate the ability to develop well-designed applications on AWS, using best development practices and ensuring the efficiency and reliability of applications.</p>
  `;

  return (
    <div className="section-home light">
      <section className='main-hero syd-hero position-relative' style={{backgroundImage:`url(${TranslationsService.labels('hero_sections.certifications.img_path')})`}}>
        <div className='my-auto mx-0'>
          <p className='dark-mode-text m-0 breadcrumb-detail'>
            <Link to='/' className='text-deco-none dark-mode-text transition-03s-eio'>Home</Link>
            &nbsp;&#9656;&nbsp;
            <Link to='/about' className='text-deco-none dark-mode-text transition-03s-eio'>{TranslationsService.labels(`menu.about.label`)}</Link>
          </p>
          <h2 className='syd-title light text-uppercase'>{TranslationsService.labels('our_certifications')}</h2>
          {
            TranslationsService.labels('hero_sections.certifications.text') &&
            <p className='dark-mode-text fs-1 m-0'>{TranslationsService.labels('hero_sections.certifications.text')}</p>
          }
        </div>
      </section>

      <div className='p-3'>
        <div className='container-fluid'>
          <div className='row'>
            {
              Object.keys(TranslationsService.labels('certifications')).map((_cert, i) => (
                <div className='col-sm-12 col-lg-4 p-3' key={i}>
                  <div className='box-certifications h-100 p-3 d-flex flex-column'>
                    <div>
                      <div className='d-flex'>
                        <img src={TranslationsService.labels(`certifications.${_cert}.logo`)} className='icon-certifications my-4' alt={TranslationsService.labels(`certifications.${_cert}.title`)}></img>
                      </div>
                      <h3 className='fw-bold dark-mode-title'>{TranslationsService.labels(`certifications.${_cert}.title`)}</h3>
                      <p dangerouslySetInnerHTML={{ __html: TranslationsService.labels(`certifications.${_cert}.desc`) }}></p>
                      {/* <p dangerouslySetInnerHTML={{ __html:  DOMPurify.sanitize(text_test) }}></p> */}
                    </div>
                    {/* <div className='syd-black transition-03s-eio btn-download-cert mt-auto'>
                      <svg xmlns="http://www.w3.org/2000/svg" width="40" height="40" fill="currentColor" className="bi bi-file-pdf" viewBox="0 0 16 16">
                        <path d="M4 0a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h8a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2H4zm0 1h8a1 1 0 0 1 1 1v12a1 1 0 0 1-1 1H4a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1z"/>
                        <path d="M4.603 12.087a.81.81 0 0 1-.438-.42c-.195-.388-.13-.776.08-1.102.198-.307.526-.568.897-.787a7.68 7.68 0 0 1 1.482-.645 19.701 19.701 0 0 0 1.062-2.227 7.269 7.269 0 0 1-.43-1.295c-.086-.4-.119-.796-.046-1.136.075-.354.274-.672.65-.823.192-.077.4-.12.602-.077a.7.7 0 0 1 .477.365c.088.164.12.356.127.538.007.187-.012.395-.047.614-.084.51-.27 1.134-.52 1.794a10.954 10.954 0 0 0 .98 1.686 5.753 5.753 0 0 1 1.334.05c.364.065.734.195.96.465.12.144.193.32.2.518.007.192-.047.382-.138.563a1.04 1.04 0 0 1-.354.416.856.856 0 0 1-.51.138c-.331-.014-.654-.196-.933-.417a5.716 5.716 0 0 1-.911-.95 11.642 11.642 0 0 0-1.997.406 11.311 11.311 0 0 1-1.021 1.51c-.29.35-.608.655-.926.787a.793.793 0 0 1-.58.029zm1.379-1.901c-.166.076-.32.156-.459.238-.328.194-.541.383-.647.547-.094.145-.096.25-.04.361.01.022.02.036.026.044a.27.27 0 0 0 .035-.012c.137-.056.355-.235.635-.572a8.18 8.18 0 0 0 .45-.606zm1.64-1.33a12.647 12.647 0 0 1 1.01-.193 11.666 11.666 0 0 1-.51-.858 20.741 20.741 0 0 1-.5 1.05zm2.446.45c.15.162.296.3.435.41.24.19.407.253.498.256a.107.107 0 0 0 .07-.015.307.307 0 0 0 .094-.125.436.436 0 0 0 .059-.2.095.095 0 0 0-.026-.063c-.052-.062-.2-.152-.518-.209a3.881 3.881 0 0 0-.612-.053zM8.078 5.8a6.7 6.7 0 0 0 .2-.828c.031-.188.043-.343.038-.465a.613.613 0 0 0-.032-.198.517.517 0 0 0-.145.04c-.087.035-.158.106-.196.283-.04.192-.03.469.046.822.024.111.054.227.09.346z"/>
                      </svg>
                      <span>Download</span>
                    </div> */}
                  </div>
                </div>
              ))
            }

          </div>
        </div>
      </div>
    </div>
  );
};

