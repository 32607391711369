import React, { useContext, useMemo, useState } from 'react';
import "./client-stories-detail.scss";
import { Link, useParams } from "react-router-dom";
import { Buffer } from 'buffer';
import { AppContext } from '../../services/translationContext';

const api = process.env.REACT_APP_URL_API;
const textstring = `
          <p>Dropsa is a leader in the design and production of centralised lubrication systems with high technological innovation, with a multinational structure and presence in the main world markets</p><p>Since 2018, Dropsa has chosen Sydea as a strategic partner for assistance and maintenance of the SAP S/4 Hana system, as well as for the adjustments - on business processes and technological solutions - necessary to support its growth path that has seen in recent years:</p><div style='display: flex; align-items: center; margin: 3rem auto;'><div style='width:50%'><img src='https://d3t3s6w5yvhc3g.cloudfront.net/images/client-stories/sydeaxdropsa.png' style='width:100%'/></div><div style='width:50%'><ul style='list-style:numeric; font-weight: bold'><li style='margin: 1rem; font-size: 1.5rem;'><b>Acquisitions of company branches in Italy and abroad</b></li><li style='margin: 1rem; font-size: 1.5rem;'><b>Opening of new foreign branches </b></li><li style='margin: 1rem; font-size: 1.5rem;'><b>Mergers of branches or plants</b></li><li style='margin: 1rem; font-size: 1.5rem;'><b>Digitisation and streamlining of business processes</b></li></ul></div></div><p>A long history that Sydea proudly honours with commitment, passion and professionalism.</p>
`;

export const ClientStoriesDetail = () => {
  let { story_id } = useParams();
  const { services: {TranslationsService} } = useContext(AppContext);
  const [storyInfo, setStoryInfo] = useState([]);

  // useMemo(() => {
  //   fetch(`${api}/content-public?url_path=${story_id}`)
  //   .then(response => response.json())
  //   .then(data => {
  //     setStoryInfo(data.data[0]);
  //     document.title = `${TranslationsService.labels(`client_stories`)} - ${data.data[0].title} | ${TranslationsService.getMainInfoCompany('name')}`;
  //   });
  // }, [TranslationsService, story_id]);

  useMemo(() => {
    // fetch(`${api}/content-public?url_path=${story_id}`)
    // .then(response => response.json())
    // .then(data => {
    //   setStoryInfo(data.data[0]);
    //   document.title = `${TranslationsService.labels(`client_stories`)} - ${data.data[0].title} | ${TranslationsService.getMainInfoCompany('name')}`;
    // });
    const story = TranslationsService.labels(`client_stories_sect`).filter(x => x.id === story_id)[0];
    setStoryInfo(story);
    document.title = `${TranslationsService.labels(`client_stories`)} - ${story.title} | ${TranslationsService.getMainInfoCompany('name')}`;
  }, [TranslationsService, story_id]);

  const getHtmlContent = () => {
    try{
      let htmlText = Buffer.from(storyInfo.content, "base64");
      return htmlText;
    }
    catch(e){
      return '';
    }

  }

  return (
    // <div className="section-home light">
    //   <section className='hero-story-det syd-hero position-relative p-0' style={{backgroundImage: storyInfo.image ?`url(https://d3t3s6w5yvhc3g.cloudfront.net/images/${storyInfo.image})`:''}}>
    //     <div className='overlay-area-info w-100 d-flex flex-column justify-content-center p-5'>
    //       <p className='dark-mode-text m-0 breadcrumb-detail'>
    //         <Link to='/' className='text-deco-none dark-mode-text transition-03s-eio'>Home</Link>
    //         &nbsp;&#9656;&nbsp;
    //         <Link to='/insights' className='text-deco-none dark-mode-text transition-03s-eio'>{TranslationsService.labels(`menu.insights.label`)}</Link>
    //         &nbsp;&#9656;&nbsp;
    //         <Link to='/insights/client-stories' className='text-deco-none dark-mode-text transition-03s-eio'>{TranslationsService.labels(`client_stories`)}</Link>
    //       </p>
    //       <h2 className='syd-title light text-uppercase'>{storyInfo.title}</h2>
    //       <p className='dark-mode-text fs-1 m-0'>{storyInfo.description}</p>
    //     </div>
    //   </section>

    //   {
    //     storyInfo.service &&
    //     <div className='d-flex flex-wrap p-3'>
    //       <Link to={`/industries/${storyInfo.industries}`} className='syd-chips transition-03s-eio px-3 py-2 me-2 mb-2 text-deco-none syd-black breadcrumb-detail'>
    //         <p className='m-0'>{TranslationsService.labels(`industries.${storyInfo.industries}.title`)}</p>
    //       </Link>
    //       <Link to={`/services/${storyInfo.service.split(',')[0]}`} className='syd-chips  transition-03s-eio px-3 py-2 me-2 mb-2 text-deco-none syd-black breadcrumb-detail'>
    //         <p className='m-0'>{TranslationsService.labels(`services.${storyInfo.service.split(',')[0]}.title`)}</p>
    //       </Link>
    //       <Link to={`/services/${storyInfo.service.split(',')[0]}/${storyInfo.service.split(',')[1]}`} className='syd-chips  transition-03s-eio px-3 py-2 me-2 mb-2 text-deco-none syd-black breadcrumb-detail'>
    //         <p className='m-0'>{TranslationsService.labels(`services.${storyInfo.service.split(',')[0]}.${storyInfo.service.split(',')[1]}.title`)}</p>
    //       </Link>
    //     </div>
    //   }

    //   <div dangerouslySetInnerHTML={{ __html: getHtmlContent() }} className='w-75 p-3 area-text-cs'></div>

    // </div>
    <div className="section-home light">
      <section className='hero-story-det syd-hero position-relative p-0' style={{backgroundImage: `url(${storyInfo.image_url})`}}>
        <div className='overlay-area-info w-100 d-flex flex-column justify-content-center p-5'>
          <p className='dark-mode-text m-0 breadcrumb-detail'>
            <Link to='/' className='text-deco-none dark-mode-text transition-03s-eio'>Home</Link>
            &nbsp;&#9656;&nbsp;
            <Link to='/insights' className='text-deco-none dark-mode-text transition-03s-eio'>{TranslationsService.labels(`menu.insights.label`)}</Link>
            &nbsp;&#9656;&nbsp;
            <Link to='/insights/client-stories' className='text-deco-none dark-mode-text transition-03s-eio'>{TranslationsService.labels(`client_stories`)}</Link>
          </p>
          <h2 className='syd-title light text-uppercase'>{storyInfo.title}</h2>
          <p className='dark-mode-text fs-3 m-0'>{storyInfo.desc}</p>
        </div>
      </section>

      {/* {
        storyInfo.service &&
        <div className='d-flex flex-wrap p-3'>
          <Link to={`/industries/${storyInfo.industries}`} className='syd-chips transition-03s-eio px-3 py-2 me-2 mb-2 text-deco-none syd-black breadcrumb-detail'>
            <p className='m-0'>{TranslationsService.labels(`industries.${storyInfo.industries}.title`)}</p>
          </Link>
          <Link to={`/services/${storyInfo.service.split(',')[0]}`} className='syd-chips transition-03s-eio px-3 py-2 me-2 mb-2 text-deco-none syd-black breadcrumb-detail'>
            <p className='m-0'>{TranslationsService.labels(`services.${storyInfo.service.split(',')[0]}.title`)}</p>
          </Link>
          <Link to={`/services/${storyInfo.service.split(',')[0]}/${storyInfo.service.split(',')[1]}`} className='syd-chips  transition-03s-eio px-3 py-2 me-2 mb-2 text-deco-none syd-black breadcrumb-detail'>
            <p className='m-0'>{TranslationsService.labels(`services.${storyInfo.service.split(',')[0]}.${storyInfo.service.split(',')[1]}.title`)}</p>
          </Link>
        </div>
      } */}

      {/* <p>{JSON.stringify(storyInfo)}</p> */}

      {/* <div dangerouslySetInnerHTML={{ __html: getHtmlContent() }} className='w-75 p-3 area-text-cs'></div> */}

      {
        Object.keys(storyInfo).length > 0 &&
        <div className='d-flex flex-wrap p-3'>

          <Link to={`/services/${storyInfo.bu_area.key}`} className='syd-chips transition-03s-eio px-2 py-1 me-2 mb-2 text-deco-none syd-black breadcrumb-detail' style={{color:'#141414'}}>
            <p className='m-0'>{TranslationsService.labels(`services.${storyInfo.bu_area.key}.title`)}
            {
              storyInfo.bu_area.sub_items &&
              <span> - {TranslationsService.labels('services')[storyInfo.bu_area.key].items[storyInfo.bu_area.sub_items].title}</span>
            }
            </p>
          </Link>
          
          {
            storyInfo.bu_service.map((service, i) => (
              storyInfo.bu_area.sub_items ?
              (
                <Link key={i} to={`/services/${storyInfo.bu_area.key}/${storyInfo.bu_area.sub_items}/${service}`} className='syd-chips transition-03s-eio px-2 py-1 me-2 mb-2 text-deco-none syd-black breadcrumb-detail' style={{color:'#141414'}}>
                  <p className='m-0 text-end'>{TranslationsService.labels('services')[storyInfo.bu_area.key].items[storyInfo.bu_area.sub_items][service].title}</p>
                </Link>
              )
              :
              (
                <Link key={i} to={`/services/${storyInfo.bu_area.key}/${service}`} className='syd-chips transition-03s-eio px-2 py-1 me-2 mb-2 text-deco-none syd-black breadcrumb-detail' style={{color:'#141414'}}>
                  <p className='m-0'>{TranslationsService.labels(`services.${storyInfo.bu_area.key}.${storyInfo.bu_service}.title`)}</p>
                </Link>
              )
            ))
          }

        </div>
      }

      {/* <span className='fw-bold dark-mode-title'>{TranslationsService.labels(`services.${_cStory.bu_area.key}.title`)}
                          {
                            _cStory.bu_area.sub_items &&
                            <span> - {TranslationsService.labels('services')[_cStory.bu_area.key].items[_cStory.bu_area.sub_items].title}</span>
                          }
                          </span>
                          <span>
                            {
                              _cStory.bu_service.map((service, i) => (
                                _cStory.bu_area.sub_items ?
                                (
                                  <p className='m-0 text-end'>{TranslationsService.labels('services')[_cStory.bu_area.key].items[_cStory.bu_area.sub_items][service].title}</p>
                                )
                                :
                                (
                                  <p className='m-0'>{TranslationsService.labels(`services.${_cStory.bu_area.key}.${_cStory.bu_service}.title`)}</p>
                                )
                              ))
                            }
                          </span> */}

      <div className='body-text p-3 area-text-cs fs-5'>
        <div dangerouslySetInnerHTML={{ __html: storyInfo.long_text }}></div>
      </div>

    </div>
  );
};
