import React, { useContext } from 'react';
import './clients.scss';
import { AppContext } from '../../services/translationContext';

export const Clients = () => {
  const { services: {TranslationsService} } = useContext(AppContext);

  return (
    // <div className="p-3 d-flex gap-5 pb-5 moving-text">
    //   <div className='marquee'>
    //     <ul className="marquee__content">
    //     {
    //       TranslationsService.getGlobalValue('clients').map((client, i) => ((
    //         <img src={`${client.image}`} alt={`${client.name}`} className='logo-client' key={i}></img>
    //       )
    //       ))
    //       }
    //     </ul>
    //     <ul className="marquee__content" aria-hidden="true">
    //     {
    //       TranslationsService.getGlobalValue('clients').map((client, i) => ((
    //         <img src={`${client.image}`} alt={`${client.name}`} className='logo-client' key={i}></img>
    //       )
    //       ))
    //     }
    //     </ul>
    //   </div>
    // </div>
    <div className='px-3 pb-5 d-flex flex-wrap gap-5 justify-content-between'>
      {
        TranslationsService.getGlobalValue('clients').map((client, i) => ((
          <img src={`${client.image}`} alt={`${client.name}`} className='logo-client-ext' key={i}></img>
        )
        ))
      }
    </div>
  );
};