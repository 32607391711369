import React, {useContext} from 'react';
import "./insights.scss";
import { AppContext } from '../../services/translationContext';
import { InsightsSection } from '../../components/sections/insights/insights';
import { ClientStoriesSection } from '../../components/sections/client-stories/client-stories';

export const Insights = () => {
  const { services: {TranslationsService} } = useContext(AppContext);
  document.title = `${TranslationsService.labels(`menu.insights.label`)} | ${TranslationsService.getMainInfoCompany('name')}`;

  return (
    <div className="section-home light">
      <section className='main-hero syd-hero position-relative' style={{backgroundImage:`url(${TranslationsService.labels('hero_sections.insights.img_path')})`}}>
        <div className='my-auto mx-0'>
          <h2 className='syd-title light text-uppercase'>{TranslationsService.labels(`menu.insights.label`)}</h2>
          {
            TranslationsService.labels(`hero_sections.insights.text`) &&
            <p className='dark-mode-text fs-1 m-0'>{TranslationsService.labels(`hero_sections.insights.text`)}</p>
          }
        </div>
      </section>
      <InsightsSection />
      {
        TranslationsService.childMenuAvailable('insightsSections.client-stories') && 
        <div className='syd-bg-dark'>
          <ClientStoriesSection />
        </div>
      }
    </div>
  );
};
