import React, { useContext, useEffect, useState, useRef } from 'react';
import './hero.scss';
import { AppContext } from '../../services/translationContext';
import { Link } from "react-router-dom";
import { Swiper, SwiperSlide } from "swiper/react";

import "swiper/css";
import "swiper/css/effect-fade";
import "swiper/css/navigation";
import "swiper/css/pagination";
import { Autoplay, EffectFade, Navigation, Pagination } from "swiper";

export const Hero = () => {
  const { services: {TranslationsService} } = useContext(AppContext);
  const [fullSlideSize, setfullSlideSize] = useState('');
  const [marginTopHero, setMarginTopHero] = useState('');
  const [displayScrollbar, setDisplayScrollbar] = useState('block');

  const swiperElRef = useRef(null);

  useEffect(() => {
    let _navbar = document.getElementById('main-nav');
    setfullSlideSize(`100%`);

    try{
      if(window.getComputedStyle(_navbar).display === 'none'){
        _navbar = document.getElementById('main-nav-mob');
      }
      setMarginTopHero(`-${_navbar.offsetHeight + 1}px`);
    }
    catch(e){}

  }, []);

  const onSlideChange = () =>{
    setDisplayScrollbar('none');
    setTimeout(() => {
      setDisplayScrollbar('block');
    }, 100);
  }

  const scrollDownHero = () =>{
    window.scrollTo(0, (window.innerHeight - 80));
  }

  return (
    <div className="hero-container">

      <div className='btn-scroll-down' onClick={scrollDownHero}>
        <svg xmlns="http://www.w3.org/2000/svg" fill="currentColor" className="bi bi-chevron-down" viewBox="0 0 16 16">
          <path fillRule="evenodd" d="M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z"/>
        </svg>
      </div>
      
      <Swiper
        ref={swiperElRef}
        spaceBetween={30}
        effect={"fade"}
        loop={true}
        autoplay={{
          delay: 10000,
          disableOnInteraction: false,
        }}
        // // navigation={true}
        // // pagination={{
        // //   type: "progressbar",
        // // }}
        // // pagination={true}
        modules={[Autoplay, EffectFade, Navigation, Pagination]}
        className="swiper-hero"
        style={{height: fullSlideSize, marginTop: marginTopHero}}
        onSlideChange={() => onSlideChange()}
      >
        {
          TranslationsService.labels(`home_page.carousel`).map((slider, i) => (
          <SwiperSlide key={i}>
            {slider.internal_link || slider.external_link ? (
              slider.internal_link ? (
                <Link to={slider.internal_link} className='text-deco-none transition-03s-eio'>
                  <div className='slide-home-hero pos-relative'>
                    <div className='slide-overlay p-5'>
                      <div className='col-slide-hero d-flex flex-column justify-content-center pt-5'>
                        <h2 className='dark-mode-text fw-bold slide-hero-title'>{slider.title}</h2>
                        <div className='box-action-hero d-flex align-items-center gap-2 transition-03s-eio'>
                          <span className='m-0 p-0'>{TranslationsService.labels(`learn_more`)}</span>
                          <span className='box-arrow-hero'>
                            <svg xmlns="http://www.w3.org/2000/svg" fill="currentColor" className="bi bi-arrow-right" viewBox="0 0 16 16">
                              <path fillRule="evenodd" d="M1 8a.5.5 0 0 1 .5-.5h11.793l-3.147-3.146a.5.5 0 0 1 .708-.708l4 4a.5.5 0 0 1 0 .708l-4 4a.5.5 0 0 1-.708-.708L13.293 8.5H1.5A.5.5 0 0 1 1 8z"/>
                            </svg>
                          </span>
                        </div>
                      </div>
                    </div>
                    <img src={slider.image} alt={slider.title} className='img-slide-hero'/>
                  </div>
                </Link>
              ) : (
                <a href={slider.external_link} target='_blank' rel='noreferrer' className='text-deco-none transition-03s-eio'>
                  <div className='slide-home-hero pos-relative'>
                    <div className='slide-overlay p-5'>
                      <div className='col-slide-hero d-flex flex-column justify-content-center pt-5'>
                        <h2 className='dark-mode-text fw-bold slide-hero-title'>{slider.title}</h2>
                        <div className='box-action-hero d-flex align-items-center gap-2 transition-03s-eio'>
                          <span className='m-0 p-0'>{TranslationsService.labels(`learn_more`)}</span>
                          <span className='box-arrow-hero'>
                            <svg xmlns="http://www.w3.org/2000/svg" fill="currentColor" className="bi bi-arrow-right" viewBox="0 0 16 16">
                              <path fillRule="evenodd" d="M1 8a.5.5 0 0 1 .5-.5h11.793l-3.147-3.146a.5.5 0 0 1 .708-.708l4 4a.5.5 0 0 1 0 .708l-4 4a.5.5 0 0 1-.708-.708L13.293 8.5H1.5A.5.5 0 0 1 1 8z"/>
                            </svg>
                          </span>
                        </div>
                      </div>
                    </div>
                    <img src={slider.image} alt={slider.title} className='img-slide-hero'/>
                  </div>
                </a>
              )
            ) : (
                <div className='slide-home-hero pos-relative'>
                  <div className='slide-overlay p-5'>
                    <div className='col-slide-hero d-flex flex-column justify-content-center pt-5'>
                      <h2 className='dark-mode-text fw-bold slide-hero-title'>{slider.title}</h2>
                    </div>
                  </div>
                  <img src={slider.image} alt={slider.title} className='img-slide-hero'/>
                </div>
            )}
          </SwiperSlide>

          ))
        }
        
        <div className='hero-progressbar' style={{display:displayScrollbar}}></div>
      </Swiper>
    </div>
  );
};